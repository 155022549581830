import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './util/rem'
import axios from 'axios'
Vue.use(axios);

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(ElementUI);

// router.beforeEach((to, from, next)=>{
//   if(to.meta.title){
//     document.title = to.meta.title
//   }
//   next()
// })

router.afterEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
});

new Vue({
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount("#app");