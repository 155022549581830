<template>
  <div id="app">
    <!-- //联系我们 和返回顶部 -->
    <div class="backTop">
      <div class="relationWe" @mouseover="changeRelationWe" @mouseout="removeRelationWe">
        <div class="qrcode_fixed" v-if="flag1">
          <img style="width: 10rem; height: 10rem" src="./assets/img/qrcode.png" alt="" />
        </div>
        <div class="imgBox">
          <img src="./assets/img/relationWe.png" alt="" />
          联系我们
        </div>
      </div>
      <div class="relationWe" @click="backTopBtn">
        <div class="imgBox">
          <img src="./assets/img/backtop.png" alt="" />
          返回顶部
        </div>
      </div>
    </div>
    <!-- //公共头部 -->
    <div class="headerBox" :class="{ headerBoxBgc: flag }">
      <div class="logo">
        <img style="width: 100%; height: 100%" src="./assets/img/logo.png" alt="" />
      </div>
      <div class="menu">
        <router-link to="/" tag="div">首页</router-link>
        <router-link to="/brandClient" tag="div">品牌客户</router-link>
        <router-link to="/channelTeam" tag="div">渠道合作</router-link>
        <router-link to="/about" tag="div">关于我们</router-link>
        <div @click="ImmediatelyConsult" style="background: #fcc02e">体验</div>
      </div>
    </div>
    <div>
      <transition mode="out-in">
        <router-view />
      </transition>
    </div>
    <div class="footer_outBox">
      <div class="footerBox">
        <!-- //左边图片部分 -->
        <div class="left_ft">
          <div style="margin-right: 6.25rem">
            <div
              style="
                text-align: left;
                margin-bottom: 2.5rem;
                width: 15.5rem;
                height: 5.25rem;
              "
            >
              <img style="width: 100%" src="./assets/img/footer_logo.png" alt="" />
            </div>
            <div class="left_phone">
              <div>
                <img
                  style="width: 1.875rem; height: 1.875rem"
                  src="./assets/img/phone_icon.png"
                  alt=""
                />
              </div>
              <div style="margin-left: 1.25rem">
                <p style="font-size: 1.25rem; color: #fff; line-height: 1.875rem">
                  18067916510
                </p>
              </div>
            </div>
          </div>
          <div>
            <div style="width: 8.375rem; height: 8.375rem">
              <img style="width: 100%" src="./assets/img/qrcode.png" alt="" />
            </div>
            <div style="font-size: 0.875rem; color: #79828c; margin-top: 0.18rem">
              赋盈企业微信
            </div>
          </div>
        </div>
        <!-- //右边文字部分 -->
        <div class="right_ft clearfix">
          <dl class="col-links">
            <dt>首页</dt>
            <dd style="cursor: pointer" @click="goto('/', 'productModel')">产品模型</dd>
            <dd style="cursor: pointer" @click="goto('/', 'advantage')">核心优势</dd>
            <dd style="cursor: pointer" @click="goto('/', 'case')">客户案例</dd>
            <dd style="cursor: pointer" @click="goto('/', 'experience')">申请体验</dd>
          </dl>
          <dl class="col-links">
            <dt>合作伙伴</dt>
            <dd style="cursor: pointer" @click="goto('/brandClient', 'brandClient')">
              品牌客户
            </dd>
            <dd style="cursor: pointer" @click="goto('/brandClient', 'ClientTalk')">
              客户说
            </dd>
          </dl>
          <dl class="col-links">
            <dt>加入我们</dt>
            <dd style="cursor: pointer" @click="goto('/channelTeam', 'joinWe')">
              十大支持
            </dd>
            <dd style="cursor: pointer" @click="goto('/channelTeam', 'advantage')">
              赋盈优势
            </dd>
            <dd style="cursor: pointer" @click="goto('/channelTeam', 'contact')">
              联系方式
            </dd>
          </dl>
          <dl class="col-links">
            <dt>关于我们</dt>
            <dd style="cursor: pointer" @click="goto('/about', 'aboutUs')">关于我们</dd>
            <dd style="cursor: pointer" @click="goto('/about', 'culture')">赋盈文化</dd>
          </dl>
        </div>
      </div>
      <div class="foot_line"></div>
      <div class="bt_text">Copyright © 2020 <a class="style_a" href="http://beian.miit.gov.cn/" target="_blank">浙ICP备19039769号-3</a> 		 	<div style="width:300px;margin:0 auto; padding:20px 0;">
		 		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010202001978" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="https://isharesource.oss-cn-hangzhou.aliyuncs.com/brand/beian/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png?versionId=CAEQMRiBgIDr48SJ6BciIDA0YmVlYTI5NGFhZDQ4M2ZiMDUxYWM3Y2IyYmZjZGQ1" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33010202001978号</p></a>
		 	</div>
		 </div>
    </div>
    <ImmediatelyConsult ref="ImmediatelyConsult"></ImmediatelyConsult>
  </div>
</template>

<script>
import ImmediatelyConsult from "./views/home/ImmediatelyConsult.vue";
function top() {
  let currentPosition, timer;
  timer = setInterval(function () {
    currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
    currentPosition -= 30;
    if (currentPosition > 0) {
      window.scrollTo(0, currentPosition);
    } else {
      window.scrollTo(0, 0);
      clearInterval(timer);
    }
  }, 1);
}
export default {
  data() {
    return {
      flag: false,
      flag1: false,
    };
  },
  methods: {
    changeRelationWe() {
      this.flag1 = true;
    },
    removeRelationWe() {
      this.flag1 = false;
    },
    backTopBtn() {
      top();
    },
    goto(page, selectID) {
      console.log(this.$route.path, "this.$route.path");
      if (page == this.$route.path) {
        let toElement = document.getElementById(selectID);
        toElement.scrollIntoView(true);
      } else {
        localStorage.setItem("toId", selectID);
        this.$router.push({ path: page });
      }
    },
    ImmediatelyConsult() {
      this.$refs.ImmediatelyConsult.show({ dialogVisible: true });
    },
    handleScroll() {
      let scrollTop = document.documentElement.scrollTop;
      let hg = document.getElementById("bannerId").offsetHeight;
      console.log(hg, scrollTop, "h11g");
      if (scrollTop > hg) {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  components: {
    ImmediatelyConsult,
  },
};
</script>

<style>
/* 公共联系我们 */
.qrcode_fixed {
  position: absolute;
  left: -10.625rem;
}
.backTop {
  position: fixed;
  right: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.relationWe {
  width: 2.5rem;
  height: 9.375rem;
  background: linear-gradient(0deg, #6a7cff, #2a43ed);
  box-shadow: 0px 0.125rem 0.3125rem 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  padding-bottom: 0.625rem;
  cursor: pointer;
}
.relationWe:nth-child(2) {
  background: #ffffff;
  margin-top: 1.25rem;
}
.relationWe:nth-child(2) .imgBox {
  color: #999 !important;
  width: 1.5rem;
  height: 1.1875rem;
}
.relationWe .imgBox {
  width: 1.875rem;
  height: 1.875rem;
  margin: 0 auto;
  padding-top: 1.25rem;
  box-sizing: border-box;
  margin-bottom: 0.625rem;
  font-size: 1.125rem;
  color: #fff;
}
.relationWe .imgBox img {
  width: 100%;
}
.headerBoxBgc {
  background-color: rgb(76, 97, 238) !important;
}
* {
  margin: 0;
  padding: 0;
}
.headerBox {
  width: 100%;
  z-index: 999;
  position: fixed;
  top: 0;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  padding: 0.625rem 22.5rem;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.1);
}
/* .headerBox:hover {
  background: rgba(0, 0, 0, 0.1);
  z-index: 998;
} */
.logo {
  width: 10.5rem;
}
.menu {
  display: flex;
  padding: 0.625rem;
  box-sizing: border-box;
}
.menu div {
  width: 6.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  color: #fff;
  font-size: 1.125rem;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 2.375rem;
  border-radius: 1.25rem;
}
/* .menu div:hover {
  background: #fcc02e;
} */
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 1.875rem;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.myactive {
  color: blue;
  font-size: 1.25rem;
  font-style: italic;
}
/* //经过 */
.v-enter,
.v-leave-to {
  opacity: 0;
  transform: translateX(3.125rem);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}
.footer_outBox {
  /* height: 22.5rem; */
  background: #2c3f53;
  padding: 5rem 22.5rem 1.875rem 22.5rem;
}
.footerBox {
  display: flex;
  justify-content: space-between;
}
.left_ft {
  display: flex;
  width: 31.25rem;
  margin-right: 8.75rem;
}
.left_phone {
  display: flex;
  margin-left: 1.25rem;
}
.right_ft {
  flex: 1;
  color: #fff;
  font-size: 1.125rem;
  text-align: left;
  display: flex;
}
.col-links {
  /* float: left; */
  width: 9.68rem;
}
.col-links:last-child {
  width: 4.68rem;
}
.col-links dt {
  font-size: 1.125rem;
  color: #fff;
  margin-bottom: 1.875rem;
}
.col-links dd {
  font-size: 0.875rem;
  color: #79828c;
  margin-bottom: 1.25rem;
}
.foot_line {
  width: 75rem;
  height: 0.0625rem;
  background: #ffffff;
  opacity: 0.2;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
}
.bt_text {
  font-size: 0.875rem;
  color: #79828c;
}
.style_a {
  color: grey !important;
  text-decoration: none !important;
}
/* 清除浮动 */
.clearfix::after {
  content: ".";
  clear: both;
  display: block;
  overflow: hidden;
  font-size: 0;
  height: 0;
}

.clearfix {
  zoom: 1;
}
</style>
