<template>
  <!-- title="申请体验" -->
  <el-dialog
    :visible.sync="dialogVisible"
    width="40rem"
    title=""
    :before-close="handleClose"
  >
    <!-- <div class="ApplyImmediately_from"> -->
    <div class="ApplyImmediately_from_tit">
      <span class="extra_line">
        <span class="color_block"></span>
        申请体验
      </span>
    </div>
    <div class="ApplyImmediately_from_ipt">
      <el-input
        style="width: 18.75rem"
        v-model="user_name"
        placeholder="请输入姓名"
      ></el-input>
    </div>
    <div class="ApplyImmediately_from_ipt">
      <el-input
        style="width: 18.75rem"
        type="number"
        v-model="mobile"
        placeholder="请输入手机号"
      ></el-input>
    </div>
    <div>
      <el-input
        style="width: 18.75rem"
        v-model="company_name"
        placeholder="请输入公司名"
      ></el-input>
    </div>
    <div class="ApplyImmediately_btn" @click="apply">提交申请</div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      input: "",
      user_name: "",
      mobile: "",
      company_name: "",
    };
  },
  methods: {
    apply() {
      if (!this.user_name || this.user_name == "") {
        this.$message.warning("姓名不可以为空，请输入姓名");
        return;
      }
      if (this.mobile.length != 11) {
        this.$message.warning("手机号格式不正确，请重新输入");
        return;
      }
      if (!this.company_name || this.company_name == "") {
        this.$message.warning("公司名不可以为空，请输入公司名");
        return;
      }
      this.$axios
        .post("http://e.ishare-go.com/websiteapi/Apply/apply", {
          user_name: this.user_name,
          mobile: this.mobile,
          company_name: this.company_name,
        })
        .then(({ data }) => {
          console.log(data, "申请体验");
          if (data.code == 1) {
            this.$message.success(data.message);
            this.user_name = this.mobile = this.company_name = "";
            this.dialogVisible = false;
          } else {
            this.$message.warning(data.message);
          }
        });
    },
    handleClose(done) {
      done();
    },
    show(option) {
      this.dialogVisible = option.dialogVisible;
    },
  },
};
</script>

<style scoped>
.extra_line {
  position: relative;
  display: inline-block;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #ccc;
}
.color_block {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 35px;
  background-color: #4d65e6;
  height: 0.25rem;
}
.ApplyImmediately_from {
  margin: 0 auto;
  width: 31.25rem;
  height: 23.9375rem;
  background: #ffffff;
  box-shadow: 0.25rem 0.375rem 0.75rem 0px rgba(47, 47, 47, 0.2);
  border-radius: 0.625rem;
  padding: 2.5rem 6.25rem;
  box-sizing: border-box;
}
.ApplyImmediately_from_tit {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 2.5rem;
}
.ApplyImmediately_from_ipt {
  margin-bottom: 1.25rem;
}
.ApplyImmediately_btn {
  margin: 0 auto;
  width: 18.75rem;
  height: 2.5rem;
  background: #4c61ee;
  border-radius: 0.3125rem;
  line-height: 2.5rem;
  color: #fff;
  margin-top: 2.5rem;
  cursor: pointer;
}
</style>

<style>
.el-dialog__title {
  font-size: 1.5rem !important;
}
</style>