<template>
  <div class="home">
    <!-- 轮播 -->
    <div id="bannerId">
      <!-- <el-carousel height="42rem"> -->
      <!-- <el-carousel-item> -->
      <img
        style="width: 100%; height: 42rem"
        src="../assets/img/banner01.jpg"
        alt=""
      />
      <!-- </el-carousel-item> -->
      <!-- <el-carousel-item>
          <img style="width: 100%" src="../assets/img/banner02.jpg" alt="" />
        </el-carousel-item> -->
      <!-- </el-carousel> -->
    </div>
    <!-- 内容区域 -->
    <div id="productModel">
      <div class="main_tit">
        <span class="extra_line">
          <span class="color_block"></span>
          五位一体化产品模型
        </span>
      </div>
      <div class="sub_tit">渠道数字化升级，实现业务快增长</div>
      <div class="center_pic">
        <img
          style="width: 100%"
          src="../assets/img/center_pic.png"
          alt=""
          srcset=""
        />
      </div>
    </div>
    <!-- 核心优势 -->
    <div class="coreAdvantage_box" id="advantage">
      <div class="coreTit">
        <span class="extra_line">
          <span class="color_block1"></span>
          核心优势
        </span>
      </div>
      <!-- 01 -->
      <div class="core_one">
        <div class="core_one_left">
          <div class="core_one_left_tit">01</div>
          <div class="core_one_left_synopsis">
            多层次的渠道商采购定价设置体系
          </div>
          <div class="core_one_left_content">
            实现不同商品（精确到SKU级别）不同身份不同的采购价
            同时支持企业设置起订门槛
          </div>
          <div class="core_one_left_btn" @click="ImmediatelyConsult">
            免费体验
          </div>
        </div>
        <div class="core_one_r">
          <img style="width: 100%" src="../assets/img/core_one.png" alt="" />
        </div>
      </div>
      <!-- 02 -->
      <div class="core_one">
        <div class="core_one_r">
          <img style="width: 100%" src="../assets/img/core_two.png" alt="" />
        </div>
        <div class="core_one_left_two">
          <div class="core_one_left_tit">02</div>
          <div class="core_one_left_synopsis">
            打造品牌企业独立的钱包支付体系
          </div>
          <div class="core_one_left_content">
            有效的解决了渠道商大额采购时的费率疑难等问题 帮助企业省出利润
          </div>
          <div class="core_one_left_btn" @click="ImmediatelyConsult">
            免费体验
          </div>
        </div>
      </div>
      <!-- 03 -->
      <div class="core_one">
        <div class="core_one_left">
          <div class="core_one_left_tit">03</div>
          <div class="core_one_left_synopsis">
            针对于渠道商的TOB营销解决方案
          </div>
          <div class="core_one_left_content">
            我们专门定制了渠道商的营销工具，解决了企业对渠道商的没有营销工具的烦恼
          </div>
          <div class="core_one_left_btn" @click="ImmediatelyConsult">
            免费体验
          </div>
        </div>
        <div class="core_one_r">
          <img
            style="width: 31.625rem; height: 23.75rem"
            src="../assets/img/core_three.png"
            alt=""
          />
        </div>
      </div>
      <!-- 04 -->
      <div class="core_one">
        <div class="core_one_r">
          <img
            style="width: 26.25rem; height: 24.875rem"
            src="../assets/img/core_four.png"
            alt=""
          />
        </div>
        <div class="core_one_left_two">
          <div class="core_one_left_tit">04</div>
          <div class="core_one_left_synopsis">丰富的大数据智能报表解决方案</div>
          <div class="core_one_left_content">
            帮助企业分析数据并给予企业决策提供依据，月度
            季度、年度总结的必备利器之一
          </div>
          <div class="core_one_left_btn" @click="ImmediatelyConsult">
            免费体验
          </div>
        </div>
      </div>
      <!-- 05 -->
      <div class="core_one">
        <div class="core_one_left">
          <div class="core_one_left_tit">05</div>
          <div class="core_one_left_synopsis">AI人工智能异常预警解决方案</div>
          <div class="core_one_left_content">
            通过大数据、AI人工智能帮助企业监控数据异常，提升无人值守时也能及时提醒企业管理者
          </div>
          <div class="core_one_left_btn" @click="ImmediatelyConsult">
            免费体验
          </div>
        </div>
        <div class="core_one_r">
          <img style="width: 100%" src="../assets/img/core_five.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 客户案例 -->
    <div class="client_example" id="case">
      <div class="client_example_l">
        <div class="transverse"></div>
        <div class="client_example_l_text">客户案例</div>
        <div class="client_example_l_btn" @click="ImmediatelyConsult">
          立即咨询
        </div>
      </div>
      <div class="client_example_l_line"></div>
      <!-- 横向轮播 -->
      <div class="client_example_r">
        <el-carousel height="12.25rem" direction="vertical" :autoplay="true">
          <el-carousel-item>
            <div class="case_item">
              <div class="case_item_pic">
                <img
                  style="width: 100%"
                  src="../assets/img/case_1.png"
                  alt=""
                />
              </div>
              <div class="case_item_synopsis">
                <div class="case_item_synopsis_tit">百草味</div>
                <div class="case_item_synopsis_text">
                  案例成绩：系统上线3个月，业绩成交超5000万元。发货时效提升2天，部门人力节约3人，新增经销商客户130家。
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="case_item">
              <div class="case_item_pic">
                <img
                  style="width: 100%"
                  src="../assets/img/case_2.png"
                  alt=""
                />
              </div>
              <div class="case_item_synopsis">
                <div class="case_item_synopsis_tit">桑生有幸</div>
                <div class="case_item_synopsis_text">
                  案例成绩：系统上线三个月新增意向加盟店351家，会员充值金额超过110万，会员分享拉新率达到80.2%，单月销售额较三个月前提高1121%。
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="case_item">
              <div class="case_item_pic">
                <img
                  style="width: 100%"
                  src="../assets/img/case_3.png"
                  alt=""
                />
              </div>
              <div class="case_item_synopsis">
                <div class="case_item_synopsis_tit">钱皇股份</div>
                <div class="case_item_synopsis_text">
                  案例成绩：几千家渠道加盟店一个月内全部上线，订单出错率为0，渠道流失率下降38%，渠道订货率提升21%，新增渠道加盟店211家，单场共创直播实现销售业绩2000万。
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="ApplyImmediately_box" id="experience">
      <div class="ApplyImmediately">立即申请体验赋盈方案</div>
      <div class="ApplyImmediately_from">
        <div class="ApplyImmediately_from_tit">
          <span class="extra_line">
            <span class="color_block2"></span>
            申请体验
          </span>
        </div>
        <div class="ApplyImmediately_from_ipt">
          <el-input v-model="user_name" placeholder="请输入姓名"></el-input>
        </div>
        <div class="ApplyImmediately_from_ipt">
          <el-input
            type="number"
            v-model="mobile"
            placeholder="请输入手机号"
          ></el-input>
        </div>
        <div>
          <el-input
            v-model="company_name"
            placeholder="请输入公司名"
          ></el-input>
        </div>
        <div class="ApplyImmediately_btn" @click="apply">提交申请</div>
      </div>
    </div>
    <ImmediatelyConsult ref="ImmediatelyConsult"></ImmediatelyConsult>
  </div>
</template>

<script>
import ImmediatelyConsult from "./home/ImmediatelyConsult.vue";
export default {
  created() {
    //创建时执行跳转锚点位置
    this.$nextTick(() => {
      this.getlocal();
    });
  },
  name: "Home",
  data() {
    return {
      input: "",
      user_name: "",
      mobile: "",
      company_name: "",
    };
  },
  methods: {
    apply() {
      if (!this.user_name || this.user_name == "") {
        this.$message.warning("姓名不可以为空，请输入姓名");
        return;
      }
      if (this.mobile.length != 11) {
        this.$message.warning("手机号格式不正确，请重新输入");
        return;
      }
      if (!this.company_name || this.company_name == "") {
        this.$message.warning("公司名不可以为空，请输入公司名");
        return;
      }
      this.$axios
        .post("http://e.ishare-go.com/websiteapi/Apply/apply", {
          user_name: this.user_name,
          mobile: this.mobile,
          company_name: this.company_name,
        })
        .then(({ data }) => {
          console.log(data, "申请体验");
          if (data.code == 1) {
            this.$message.success(data.message);
            this.user_name = this.mobile = this.company_name = "";
          } else {
            this.$message.warning(data.message);
          }
        });
    },
    //从我本地找到id
    getlocal() {
      //找到锚点id
      let selectId = localStorage.getItem("toId");
      let toElement = document.getElementById(selectId);
      //如果对应id存在，就跳转
      if (selectId) {
        console.log(toElement, "toElement");
        toElement.scrollIntoView(true);
      }
    },

    ImmediatelyConsult() {
      this.$refs.ImmediatelyConsult.show({ dialogVisible: true });
    },
  },
  //离开页面进行对localStorage  id销毁，避免其他入口进来有锚点问题
  destroyed() {
    localStorage.setItem("toId", "");
  },
  components: {
    ImmediatelyConsult,
  },
};
</script>

<style scoped>
.main_tit {
  color: #333;
  font-size: 2.125rem;
  margin-top: 6.25rem;
  margin-bottom: 2.5rem;
}
.sub_tit {
  font-size: 1.125rem;
  color: #5d5d5d;
  margin-bottom: 3.75rem;
}
.center_pic {
  width: 73.125rem;
  margin: 0 auto;
}
.coreAdvantage_box {
  margin-top: 12.1875rem;
  margin-bottom: 8.375rem;
  padding: 0 26.25rem;
  text-align: left;
}
.coreTit {
  font-size: 2.125rem;
  color: #333;
  text-align: center;
  margin-bottom: 5rem;
}
.core_one {
  display: flex;
  justify-content: space-between;
  margin-top: 8.75rem;
}
.core_one:first-child {
  margin-top: 0;
}
.core_one_left {
  width: 27.9375rem;
  padding-right: 7.75rem;
}
.core_one_left_tit {
  font-size: 5rem;
  font-weight: bold;
  color: #4c61ee;
  opacity: 0.3;
  margin-bottom: 0.625rem;
}
.core_one_left_synopsis {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 1.875rem;
}
.core_one_left_content {
  font-size: 1.125rem;
  color: #747474;
}
.core_one_left_btn {
  padding: 0.75rem 1.5rem;
  background: #4c61ee;
  border-radius: 1.25rem;
  text-align: center;
  color: #fff;
  width: 7.5rem;
  box-sizing: border-box;
  margin-top: 3.125rem;
  cursor: pointer;
}
.core_one_r {
  width: 31.875rem;
  height: 18.75rem;
}
/* //复制新增 */
.core_one_left_two {
  padding-right: 0px;
  width: 27.9375rem;
}
.client_example {
  /* height: 322.8125rem; */
  background: #4c61ee;
  margin-bottom: 6.25rem;
  padding: 6.25rem 10.85rem 6.25rem 22.5rem;
  box-sizing: border-box;
  display: flex;
}
.transverse {
  width: 3.75rem;
  height: 0.5rem;
  background: #ffffff;
  border-radius: 0.25rem;
}
.client_example_l {
  margin-right: 13.75rem;
}
.client_example_l_text {
  font-size: 2.125rem;
  color: #ffffff;
  margin: 2.5rem 0;
  text-align: left;
}
.client_example_l_btn {
  width: 9.6875rem;
  height: 2.75rem;
  line-height: 2.75rem;
  text-align: center;
  border: 0.0625rem solid #ffffff;
  border-radius: 1.375rem;
  color: #fff;
  cursor: pointer;
}
.client_example_l_line {
  width: 0.0625rem;
  height: 10.3125rem;
  background: #ffffff;
  margin-right: 13.75rem;
}
.client_example_r {
  width: 40.75rem;
}
.case_item {
  display: flex;
}
.case_item_pic {
  width: 10.25rem;
  height: 10.25rem;
  margin-right: 2.5rem;
  background-color: #fff;
  border-radius: 1.25rem;
}
.case_item_synopsis {
  width: 25rem;
}
.case_item_synopsis_tit {
  font-size: 2.125rem;
  color: #ffffff;
  text-align: left;
  margin-bottom: 1.25rem;
}
.case_item_synopsis_text {
  font-size: 1.125rem;
  color: #ffffff;
  text-align: left;
  opacity: 0.8;
}
.ApplyImmediately_box {
  margin-bottom: 6.25rem;
}
.ApplyImmediately {
  font-size: 2.125rem;
  color: #333333;
  margin-bottom: 3.75rem;
}
.ApplyImmediately_from {
  margin: 0 auto;
  width: 31.25rem;
  height: 23.9375rem;
  background: #ffffff;
  box-shadow: 0.25rem 0.375rem 0.75rem 0px rgba(47, 47, 47, 0.2);
  border-radius: 0.625rem;
  padding: 2.5rem 6.25rem;
  box-sizing: border-box;
}
.ApplyImmediately_from_tit {
  font-size: 1.5rem;
  color: #333333;
  margin-bottom: 2.5rem;
}
.ApplyImmediately_from_ipt {
  margin-bottom: 1.25rem;
}
.ApplyImmediately_btn {
  width: 18.75rem;
  height: 2.5rem;
  background: #4c61ee;
  border-radius: 0.3125rem;
  line-height: 2.5rem;
  color: #fff;
  margin-top: 2.5rem;
  cursor: pointer;
}
.box_sareen {
  position: absolute;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.4);
  width: 100%; /*宽度设置为100%，这样才能使隐藏背景层覆盖原页面*/
  height: 100%;
  filter: alpha(opacity=60); /*设置透明度为60%*/
  opacity: 0.6; /*非IE浏览器下设置透明度为60%*/
  display: none;
  z-index: 999;
}
.extra_line {
  position: relative;
  display: inline-block;
  padding-bottom: 1.25rem;
  border-bottom: 0.0625rem solid #ccc;
}
.color_block {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 6.625rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
.color_block1 {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 2.75rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
.color_block2 {
  position: absolute;
  bottom: -0.125rem;
  display: inline-block;
  width: 30%;
  left: 2.1875rem;
  background-color: #4d65e6;
  height: 0.25rem;
}
</style>
